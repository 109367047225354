import { Header } from "../../components/Header"
import React, { useState, useEffect } from 'react';
import { Pagination, Stack, ThemeProvider, createTheme } from '@mui/material';
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';
import axios from 'axios';

const theme = createTheme({
    palette: {
        primary:{
            main: '#B6872D',
            secondary: '#FFFFFF',
            contrastText: '#FFFFFF'
        }
    }
})

export const Photos = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [page, setPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      useEffect(() => {
        axios.get('https://api.dhquartz.com/api/categrory_realisations')
          .then((response) => {
            setCategories(response.data['hydra:member']);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            // Gérez les erreurs ici, si nécessaire
          });
      }, []);

    let constantValue = 2;

    if (windowWidth > 1490) {
        constantValue = 6;
    } else if (windowWidth > 1024) {
        constantValue = 4;
    }

    const handleChangePage = (event, value) => {
        setPage(value)
    }

    const startIndex = (page - 1) * constantValue
    const endIndex = startIndex + constantValue

    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Réalisations de l'entreprise | D.H. Quartz</title>
            </Helmet>
            <Header title="Nos réalisations" />
            <p className="w-[80%] flex justify-center">Cliquez sur l’une des réalisations afin d’accéder à la galerie de celle-ci ! </p>
            <div className="lg:w-[70%] w-[60%] grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-x-40 gap-y-10 lg:mt-16 mt-8">
                {isLoading ? "Chargement en cours" : categories.slice(startIndex, endIndex).map(categorie => (
                    <Link to={`/photo/${categorie.id}`} className="flex justify-center items-center w-full lg:h-[250px] h-[200px] relative transition ease-in-out hover:scale-110 duration-300 hover:shadow-2xl">
                        <img src={"https://api.dhquartz.com/build/images/" + categorie.image} alt="Test" className='w-full h-full object-cover' />
                        <div className="absolute w-full z-20 h-full bg-black/60 p-4 flex items-end justify-center text-center text-white lg:text-4xl text-3xl font-semibold">
                            <p>{categorie.name}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="flex flex-row items-center justify-center space-x-2 lg:mt-16 mt-8">
                <ThemeProvider theme={theme}>
                    <Stack spacing={2} justifyContent="center" py={1} px={4} className='bg-white shadow-2xl rounded-full'>
                        {isLoading ? "Chargement en cours" : 
                            <Pagination 
                                count={Math.ceil(categories.length / constantValue)}
                                page={page}
                                onChange={handleChangePage}
                                color='primary'
                            />
                        }
                    </Stack>
                </ThemeProvider>
            </div>
            <div className="bg-[#B6872D] h-[2px] w-[30%] lg:mt-16 mt-8 rounded-full"></div>
        </div>
    )
}