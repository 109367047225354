import Logo from "../../assets/Logo2.svg"
import { Link } from "react-router-dom"
import fb from "../../assets/fb.svg"
import lin from "../../assets/in.svg"

function Footer() {
    return (
        <div className="w-full flex flex-col justify-center items-center my-16 space-y-8">
            <div className="w-4/5 flex lg:flex-row flex-col lg:justify-between justify-center items-center">
                <div>
                    <img src={Logo} alt="Logo" className="w-[300px]"/>
                </div>
                <div className="grid lg:grid-cols-3 grid-cols-3 gap-x-10 gap-y-4 lg:text-xl text-xs w-full lg:w-[70%]">
                    <Link to="/" href="/">Accueil</Link>
                    <div></div>
                    <Link to="/" href="/">Nous suivre :</Link>
                    <Link to="/gestion-des-acces" href="/gestion-des-acces">Gestion des accès</Link>
                    <Link to="/contact" href="/contact">Contact</Link>
                    <div className="flex flex-row items-center space-x-4">
                        <a href="https://comsea.fr/" target="_blank" rel="noreferrer"><img src={fb} alt="Facebook" className="lg:w-[30px] w-[20px]" /></a>
                        <a href="https://comsea.fr/" target="_blank" rel="noreferrer"><img src={lin} alt="Linkedin" className="lg:w-[30px] w-[20px]" /></a>
                    </div>
                    <Link to="/gestion-des-temps-alarmes-incendies-et-ppms" href="/gestion-des-temps-alarmes-incendies-et-ppms">Gestion du temps</Link>
                    <Link to="/confidentialite" href="/confidentialite">Politique de confidentialité</Link>
                    <div></div>
                    <Link to="/gestion-du-patrimoine" href="/gestion-du-patrimoine">Gestion du patrimoine</Link>
                    <Link to="/mentions" href="/mentions">Mentions légales</Link>
                    <div></div>
                    <Link to="/gestion-de-la-securité" href="/gestion-de-la-securité">Gestion de la sécurité</Link>
                </div>
            </div>
            <p className="lg:w-2/3 w-4/5 flex justify-center items-end lg:text-lg text-sm text-center">DH QUARTZ - Entreprise dans la gestion du temps, des accès, de la sécurité et des enseignes dans les Ardennes<br/><br/>03 24 56 28 38 - 35 Rue d'Étion, 08000 Charleville-Mézières</p>
            <p className="lg:w-1/3 w-4/5 flex justify-center items-end lg:text-lg text-sm lg:text-center text-start">Tout droits réservés © 2023 Copyright: <a href="https://comsea.fr/" target="_blank" rel="noreferrer" className="hover:underline">COMSEA</a></p>
        </div>
    )
}

export default Footer